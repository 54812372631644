<!-- 首页友情链接 -->
<template>
    <div class="footer">
        <div class="footer-a">
            <ul>
                <li><h4>友情链接:</h4></li>
                <li><a  href="http://www.cwl.gov.cn/" target="_blank">中国福利彩票</a></li>
                <li><a href="https://www.lottery.gov.cn/" target="_blank">中国体育彩票</a></li>
                <li><a href="https://www.cjcp.com.cn/" target="_blank">彩经网</a></li>
                <li><a href="https://www.zhcw.com/" target="_blank">中彩网</a></li>
                <li><a href="https://lottery.sina.com.cn/" target="_blank">新浪彩票</a></li>
                <li><a href="https://www.500.com/" target="_blank">500彩票</a></li>
                <li><a href="https://www.78500.cn/" target="_blank">彩宝贝</a></li>
                <li><a href="https://www.55128.cn/" target="_blank">彩吧助手</a></li>
                <li><a href="https://www.310win.com/" target="_blank">彩客网</a></li>
                <li><a href="https://tools.ssqzj.com/" target="_blank">3D之家</a></li>
                <li><a href="http://www.5566.net/cp1-.htm" target="_blank">彩票网大全</a></li>
            </ul>
            <div class="clear"></div>
        </div>
        <div class="footer-b">
            <ul>
                <ol>优彩乐网提示您：平台提供的彩票走势图，内容仅供参考；平台不从事彩票销售业务，也不提供彩票委托投注服务。
                </ol>
                <ol class="footer-ol">
                    <li>本站介绍</li>
                    <li>联系我们</li>
                    <li>免责声明</li>
                    <li>站点地图 </li>
                    <li>加入收藏夹</li>
                    <li>返回顶部</li>
                    <div class="clear"></div>
                </ol>
                <ol>版权所有：优彩乐彩票网 www.sdycl.net (©2018-2023) </ol>
                <ol>工信部备案号:鲁ICP备19048882号-5</ol>
                <div class="clear"></div>
            </ul>
        </div>
    </div> 
</template>

<script>
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
export default {
name: '',
components: {

},
data(){ // 数据源
    return{
    } 
},
mounted() {

},
computed:{
},
methods:{ // 函数体部分，js的主要逻辑控制               
},
props: {
}
}
</script>

<style scoped>
     /* @import './swiper.css'; */
.footer{
    width:100%;
    font-size:14px;
}
.footer-a{
    width:100%;  
    height:60px;
    font-size:15px;
}
.footer-a ul li{
    list-style:none;
    float:left;
    width:8%;
    margin-top:20px;
    height:20px;
    border-right:2px solid rgb(148, 145, 145);
}
.footer-b{
    width:100%;
    height:150px;
    background-color:rgb(99, 96, 96);
}
.footer-b ul li{
    list-style:none;
    width:15%;
    float:left;
    margin-left:1%;
    margin-top:8px;
    border-right:2px solid rgb(160, 157, 157);
    color:#fff;
}
ol{
    margin-top:10px;
    color:#fff;
}
.footer-ol{
    width:50%;
    margin-left:25%;
}
</style>