<!-- 说明 -->
<template>
    <div class="">
        <div class="a">
            <div style="font-size: 1.3em;margin-left:46%">走势图说明</div>
            <div class="a1">
                <ul>
                    <li><strong>和值:</strong>指定部分开奖号码相加的总和称为和值(比如双色球红球和值,则是六个红球依次相加和为128,即和值为128.)</li>
                    <li><strong>和尾:</strong>指定部分开奖号码相加的总和的尾数称为和尾(比如大乐透前区和尾,则是开奖的五个前区号码依次相加的总和是98,即和尾为8)</li>
                    <li><strong>AC值:</strong>如福利彩票双色球第08012期开奖号码为:03、04、05、16、20、30,正选数量为6,在这6个开奖号码中计算得到任意两个数字之间的不相同的正差值的总个数为14，那么这组号码的AC值就是14-（6-1）=9。
                    </li>
                    <li><strong>跨度:</strong>跨度是指1注开奖号码中最大数字与最小数字的差(绝对值)称为“号码跨度”。</li>
                    <li><strong>奇偶:</strong>能被2整除的号码为偶数,不能被2整除的号码为奇数。双色球红球中奇偶数如下:
                        [奇数] 01 03 05 07 09 11 13 15 17 19 21 23 25 27 29 31 33
                        [偶数] 02 04 06 08 10 12 14 16 18 20 22 24 26 28 30 32</li>
                    <li><strong>奇偶比:</strong>开奖红球号码中奇数个数和偶数个数之比为红球奇偶比。示例:3:3表示3个奇数,3个偶数。</li>
                    <li><strong>三区比:</strong>将彩票走势图(号码)按三个区间来划分,统计每期各区间的落球情况,按落球数量作比,如1:2:3,或者2:2:2,俗称三区比。</li>
                    <li><strong>1234走势图:</strong>1(1,2,3,4)---2(5,6,7,8)---3(9,10,11,12)---4(13,14,15,16)</li>
                    <li><strong>0129走势图:</strong>0(10,12,14,16)---1(1,3,5,7)---2(2,4,6,8)---9(9,11,13,15)</li>
                    <li><strong>3456走势图:</strong>3(1,3,10,12)---4(2,4,9,11)---5(5,7,14,16)---6(6,8,13,15)</li>
                   
                </ul>
            </div>
            <div class="a2">
                <ul>
                    <li><strong>012路:</strong>0路(0,3,6,9)--- 1路(1,4,7)--- 2路(2,5,8)</li>
                    <li><strong>147路:</strong>1路(1,5,6,9)--- 4路(0,2,4)--- 7路(3,7,8)</li>
                    <li><strong>258路:</strong>2路(2,7,9)--- 5路(0,3,5)--- 8路(1,4,6,8)</li>
                    <li><strong>大小:</strong>小:(0,1,2,3,4)---大:(5,6,7,8,9)</li>
                    <li><strong>质合:</strong>质:(1,2,3,5,7)---合:(0,4,6,8,9)</li>
                    <li><strong>振幅:</strong>振幅是指本期开奖号码与上期开奖号码按位比较的差值。</li>
                    <li><strong>五行:</strong>金:(1,9),木:(2,8),水:(3,7),火:(4,6),土:(0,5)</li>
                    <li><strong>二个尾数相加:</strong>相邻二期开奖号码相加的尾数</li>
                    <li><strong>三个尾数相加:</strong>相邻三期开奖号码相加的尾数</li>
                    <li><strong>后区差值:</strong>是指大乐透后区两个号码的差值</li>

                </ul>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        components: {

        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {

        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .a {
        width: 100%;
        text-align: left;
        border: 1px solid rgb(238, 235, 235);
    }

    .a1 {
        float: left;
        width: 49%;

    }

    .a2 {
        float: left;
        width: 49%;

    }

    ul,
    li {
        border: 1px solid rgb(238, 235, 235);
    }

    .clear {
        clear: both;
    }
</style>